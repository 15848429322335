import React from 'react'
import Helmet from 'react-helmet'
import emailjs from 'emailjs-com'

import Layout from '../components/layout';
import Gallery from '../components/Gallery';
import Experience from '../components/Experience';

import * as Constants from '../assets/sitedata.js';


class HomeIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {contact_name:'', message_html:'', contact_email:'', thankyou:false};
      }

      handleFormChange = (event) => {
       
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    sendMail = () => {
        emailjs.init("user_28M1u40gXd7OrNJrgwKZm");
        var templateParams = {
            contact_name: this.state.contact_name,
            message_html: this.state.message_html,
            contact_email: this.state.contact_email
        };
        //console.log(this.state);
        emailjs.send('default_service', 'template_UtbbqtLp', templateParams)
        
            .then(function(response) {
               //console.log('SUCCESS!', response.status, response.text);
               this.setState({thankyou:true});
               
            }.bind(this), function(error) {
               //console.log('FAILED...', error);
        });
    }


    render() {

        const siteTitle = "David Correa - Front end web developer"
        const siteDescription = "David Correa - Front end web developer"

        let thanks = '';

        if (this.state.thankyou) {
            thanks = 'Thank you, your email has been sent.'
        }

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                        
                </Helmet>

                <div id="main">

                    <section>
                        <header className="major">
                            <h2>I love working on projects from scratch and seeing the results come to life.</h2>
                        </header>
                        <p>I've had the opportunity to collaborate on projects for Tiffany, Wimbledon, US Open, Tony Awards, TD Ameritrade, among others. Take a look!</p>
                        {/* <ul className="actions">
                            <li><a href="#" className="button">Learn More</a></li>
                        </ul> */}
                    </section>

                    <section>
                        <h2>Selected Projects</h2>
                        <div className="projects">
                            <Gallery images={Constants.TIFFANY_IMAGES.map(({ id, source, thumbnail, caption, description, project }) => ({
                                source,
                                thumbnail,
                                caption,
                                description, 
                                project
                            }))} />

                            <Gallery images={Constants.USOPEN_IMAGES.map(({ id, source, thumbnail, caption, description, project }) => ({
                                source,
                                thumbnail,
                                caption,
                                description, 
                                project
                            }))} />        

                            <Gallery images={Constants.WIMBLEDON_IMAGES.map(({ id, source, thumbnail, caption, description, project }) => ({
                                source,
                                thumbnail,
                                caption,
                                description, 
                                project
                            }))} />                                                                    

                            <Gallery images={Constants.AUSOPEN_IMAGES.map(({ id, source, thumbnail, caption, description, project }) => ({
                                source,
                                thumbnail,
                                caption,
                                description, 
                                project
                            }))} />  


                            <Gallery images={Constants.TONYS_IMAGES.map(({ id, source, thumbnail, caption, description, project }) => ({
                                source,
                                thumbnail,
                                caption,
                                description, 
                                project
                            }))} />    

                        </div>
                        {/* <ul className="actions">
                            <li><a href="#" className="button">Full Portfolio</a></li>
                        </ul> */}
                    </section>
                    <section>
                        <h2>Experience</h2>
                        <div className="tech">
                        <Experience/>
                        </div>
                    </section>
                    <section>
                        <h2>Get In Touch</h2>
                        <p>I'd love to help out with your project!<br/> Feel free to contact me about creating responsive, accesible websites, hybrid mobile applications or single page applications.</p>
                        <div className="row">
                            <div className="8u 12u$(small)">
                                <form method="post" action="#">
                                    <div className="row uniform">
                                        <div className="6u 12u$(xsmall)"><input type="text" name="contact_name" id="name" placeholder="Name" onChange={this.handleFormChange} value={this.state.contact_name} /></div>
                                        <div className="6u 12u$(xsmall)"><input type="email" name="contact_email" id="email" placeholder="Email" onChange={this.handleFormChange} value={this.state.contact_email} /></div>
                                        <div className="12u"><textarea name="message_html" id="message" placeholder="Message" rows="4" onChange={this.handleFormChange} value={this.state.message_html}></textarea></div>
                                    </div>
                                </form>
                                <ul className="actions">
                                    <li><input type="submit" value="Send Message" onClick={this.sendMail} /></li>
                                </ul>
                            </div>
                        </div>
                        {thanks}
                    </section>
                    
                </div>

            </Layout>
        )
    }
}

export default HomeIndex