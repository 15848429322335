
//Tiffany
import tiffany_thumb01 from '../assets/images/thumbs/tiffany/01.png'
import tiffany_full01 from '../assets/images/fulls/tiffany/tiffany-01.jpg'
import tiffany_full02 from '../assets/images/fulls/tiffany/tiffany-02.jpg'
import tiffany_full03 from '../assets/images/fulls/tiffany/tiffany-03.jpg'
import tiffany_full04 from '../assets/images/fulls/tiffany/tiffany-04.jpg'
import tiffany_full05 from '../assets/images/fulls/tiffany/tiffany-05.jpg'
import tiffany_full06 from '../assets/images/fulls/tiffany/tiffany-06.png' 

//USOpen
import usopen_thumb01 from '../assets/images/thumbs/usopen/01.png'
import usopen_full01 from '../assets/images/fulls/usopen/usopen-01.jpg'
import usopen_full02 from '../assets/images/fulls/usopen/usopen-02.jpg'
import usopen_full03 from '../assets/images/fulls/usopen/usopen-03.png'
import usopen_full04 from '../assets/images/fulls/usopen/usopen-04.jpg'

//Wimbledon
import wimbledon_thumb01 from '../assets/images/thumbs/wimbledon/01.png'
import wimbledon_full01 from '../assets/images/fulls/wimbledon/wimbledon-01.jpg'
import wimbledon_full02 from '../assets/images/fulls/wimbledon/wimbledon-02.jpg'
import wimbledon_full03 from '../assets/images/fulls/wimbledon/wimbledon-03.png'
import wimbledon_full04 from '../assets/images/fulls/wimbledon/wimbledon-04.jpg'

//TonyAwards
import tonys_thumb01 from '../assets/images/thumbs/tonys/01.png'
import tonys_full01 from '../assets/images/fulls/tonys/tonys-01.png'
import tonys_full02 from '../assets/images/fulls/tonys/tonys-02.png'

//TonyAwards
import ausopen_thumb01 from '../assets/images/thumbs/ausopen/01.png'
import ausopen_full01 from '../assets/images/fulls/ausopen/ausopen-01.png'
import ausopen_full02 from '../assets/images/fulls/ausopen/ausopen-02.png'

//Tech stack
import html5 from '../assets/images/thumbs/stack/html5.png'
import react from '../assets/images/thumbs/stack/react.png'
import js from '../assets/images/thumbs/stack/js.png'
import es6 from '../assets/images/thumbs/stack/es6.png'
import jquery from '../assets/images/thumbs/stack/jquery.png'
import node from '../assets/images/thumbs/stack/node.png'
import css from '../assets/images/thumbs/stack/css3.png'
import sass from '../assets/images/thumbs/stack/sass.png'
import bootstrap from '../assets/images/thumbs/stack/bootstrap.png'
import a11y from '../assets/images/thumbs/stack/a11y.png'
import git from '../assets/images/thumbs/stack/git.png'
import svn from '../assets/images/thumbs/stack/svn.png'
import json from '../assets/images/thumbs/stack/json.png'
import api from '../assets/images/thumbs/stack/api.png'
import sketch from '../assets/images/thumbs/stack/sketch.png'
import photoshop from '../assets/images/thumbs/stack/photoshop.png'
import shopify from '../assets/images/thumbs/stack/shopify.png'
import wordpress from '../assets/images/thumbs/stack/wordpress.png'
import jekyll from '../assets/images/thumbs/stack/jekyll.png'

export const TIFFANY_IMAGES = [
    { id: '1', project: 'Tiffany & Co. - Tiffany Tattoo Parlor', source: tiffany_full01, thumbnail: tiffany_thumb01, caption: 'Tiffany & Co. - Tiffany Tattoo Parlor', description: 'Developed a web application for Tiffanys Valentines Day campaign where users can create and share a digital tattoo.'},
    { id: '2', project: 'Tiffany & Co. - Tiffany Tattoo Parlor', source: tiffany_full02, thumbnail: tiffany_thumb01, caption: 'Tiffany - Users can select one of the predefined tattoo shapes', description: 'Tiffany'},
    { id: '3', project: 'Tiffany & Co. - Tiffany Tattoo Parlor', source: tiffany_full03, thumbnail: tiffany_thumb01, caption: 'Tiffany - Different parts of the image, as well as the background can have a custom color', description: 'Tiffany'},
    { id: '4', project: 'Tiffany & Co. - Tiffany Tattoo Parlor', source: tiffany_full04, thumbnail: tiffany_thumb01, caption: 'Tiffany - The messaging and font can be customized', description: 'Tiffany'},
    { id: '5', project: 'Tiffany & Co. - Tiffany Tattoo Parlor', source: tiffany_full05, thumbnail: tiffany_thumb01, caption: 'Tiffany - The messaging and font can be customized', description: 'Tiffany'},
    { id: '6', project: 'Tiffany & Co. - Tiffany Tattoo Parlor', source: tiffany_full06, thumbnail: tiffany_thumb01, caption: 'Tiffany - Mobile view', description: 'Tiffany'},
];

export const USOPEN_IMAGES = [
    { id: '1', project: 'US Open', source: usopen_full01, thumbnail: usopen_thumb01, caption: 'US Open - IBM Slamtracker', description: 'Developed reusable UI components and charts for IBM Slamtracker'},
    { id: '2', project: 'US Open', source: usopen_full02, thumbnail: usopen_thumb01, caption: 'US Open - Mobile views', description: 'US Open'},
    { id: '3', project: 'US Open', source: usopen_full03, thumbnail: usopen_thumb01, caption: 'US Open - Desktop views', description: 'US Open'},
    { id: '4', project: 'US Open', source: usopen_full04, thumbnail: usopen_thumb01, caption: 'US Open - Charts', description: 'US Open'}
];

export const WIMBLEDON_IMAGES = [
    { id: '1', project: 'Wimbledon', source: wimbledon_full01, thumbnail: wimbledon_thumb01, caption: 'Wimbledon -  IBM Slamtracker', description: 'Developed the UI views for IBM Slamtracker'},
    { id: '2', project: 'Wimbledon', source: wimbledon_full02, thumbnail: wimbledon_thumb01, caption: 'Wimbledon - Charts', description: 'Wimbledon'}, 
    { id: '3', project: 'Wimbledon', source: wimbledon_full03, thumbnail: wimbledon_thumb01, caption: 'Wimbledon - Live match data display', description: 'Wimbledon'},
    { id: '4', project: 'v', source: wimbledon_full04, thumbnail: wimbledon_thumb01, caption: 'Wimbledon - Social media analysis', description: 'Wimbledon'}
];

export const TONYS_IMAGES = [
    { id: '1', project: 'Tony Awards', source: tonys_full01, thumbnail: tonys_thumb01, caption: '2015, 2017 Tony Awards', description: 'Updated the entire website content and developed the styling from scratch for the 2015 and 2016 editions of the Tony Awards'},
    { id: '2', project: 'Tony Awards', source: tonys_full02, thumbnail: tonys_thumb01, caption: '2015, 2017 Tony Awards', description: 'Tony Awards'},
];

export const AUSOPEN_IMAGES = [
    { id: '1', project: 'Australian Open', source: ausopen_full01, thumbnail: ausopen_thumb01, caption: 'Australian Open - Match Centre', description: 'Developed mobile and desktop views for the Australian Open'},
    { id: '2', project: 'Australian Open', source: ausopen_full02, thumbnail: ausopen_thumb01, caption: 'Australian Open - Mobile views', description: 'Australian Open'},
];

export const TECH_IMAGES = [
    { id: '1', name: 'HTML5', thumbnail: html5, duration: '12 years'},
    { id: '2', name: 'ReactJS', thumbnail: react, duration: '3 years'},
    { id: '3', name: 'Javascript', thumbnail: js, duration: '10 years'},
    { id: '4', name: 'ES6', thumbnail: es6, duration: '2 years'},
    { id: '5', name: 'jQuery', thumbnail: jquery, duration: '8 years'},
    { id: '6', name: 'nodeJS', thumbnail: node, duration: '2 years'},
    { id: '7', name: 'CSS', thumbnail: css, duration: '11 years'},
    { id: '8', name: 'SCSS', thumbnail: sass, duration: '4 years'},
    { id: '9', name: 'Bootstrap', thumbnail: bootstrap, duration: '4 years'},
    { id: '10', name: 'Accessibility', thumbnail: a11y, duration: '2 years'},
    { id: '11', name: 'git', thumbnail:git , duration: '4 years'},
    { id: '12', name: 'Subversion', thumbnail: svn, duration: '2 years'},
    { id: '13', name: 'JSON', thumbnail: json, duration: '7 years'},
    { id: '14', name: 'API integration', thumbnail: api, duration: '7 years'},
    { id: '15', name: 'Sketch', thumbnail: sketch, duration: '1 year'},
    { id: '16', name: 'Photoshop', thumbnail: photoshop, duration: '10 years'},
    { id: '17', name: 'Shopify', thumbnail: shopify, duration: '1 year'},
    { id: '18', name: 'Wordpress', thumbnail: wordpress, duration: '4 years'},
    { id: '19', name: 'Jekyll', thumbnail: jekyll, duration: '6 months'},
];