import React from 'react';
import * as Constants from '../assets/sitedata.js'


const Experience = () => {

  let experienceItems = Constants.TECH_IMAGES.map((tech,index) => {
    return (               
        <article className="item" key={index}>
            <img src={tech.thumbnail} alt={tech.name} />
          <h4>{tech.name}</h4>  
          <p>{tech.duration}</p>
      </article>
      );
  });

  return (<>{experienceItems}</>)

};
export default Experience;